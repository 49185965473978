import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorPopupComponent } from '../../popup/error-popup/error-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private dialog: MatDialog, private translate: TranslateService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    return next.handle(request).pipe(catchError((x) => this.handleError(x)));
  }

  private handleError(err: HttpErrorResponse): Observable<any> {
    /* 
    const error = {
      statusCode: err.error.statusCode,
      url: err.error.url.replace('uri=/aiengine/api/', ''),
      details: err.error.details,
      message: 'Error with ChatGpt api',
    };
    */

    if (err.status != 0 && err.error != null && typeof err.error != 'string') {
      if (err.error.statusCode == 401) {
        this.translate
          .get('tokenError')
          .subscribe((translatedMessage: string) => {
            this.openErrorPopup({
              statusCode: err.error.statusCode,
              url: err.error.url.replace('uri=/aiengine/api/', ''),
              details: err.error.details,
              message: translatedMessage,
            });
          });
      } else if (err.error.statusCode == 404 || err.error.statusCode == 406) {
      } else if (
        err &&
        err.error &&
        err.error.statusCode &&
        (err.error.url.includes('askDTA') ||
          err.error.url.includes('askDTANA') ||
          err.error.url.includes('documentSearch')) &&
        err.error.statusCode == 408
      ) {
        this.translate.get('timeout').subscribe((translatedMessage: string) => {
          this.openErrorPopup({
            statusCode: err.error.statusCode,
            url: err.error.url.replace('uri=/aiengine/api/', ''),
            details: err.error.details,
            message: translatedMessage,
          });
        });
      } else if (
        err &&
        err.error &&
        err.error.statusCode &&
        (err.error.url.includes('askDTA') || err.error.url.includes('askDTANA'))
      ) {
        this.openErrorPopup({
          statusCode: err.error.statusCode,
          url: err.error.url.replace('uri=/aiengine/api/', ''),
          details: err.error.details,
          message: 'Error with ChatGpt api',
        });
      }
    } else {
      if (
        err.url?.includes('getSubscribedItemsNumber') ||
        err.url?.includes('getItemsNumber')
      ) {
      } else {
        this.translate.get('error').subscribe((translatedMessage: string) => {
          this.openErrorPopup({
            url: 'Something went wrong',
            details: err.error.details,
            message: translatedMessage,
          });
        });
      }
    }
    return throwError(err);
  }

  openErrorPopup(error: any) {
    if (this.dialog.openDialogs.length == 0) {
      this.dialog.open(ErrorPopupComponent, {
        disableClose: true,
        data: error,
      });
    }
  }
}
