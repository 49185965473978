import { FormCustomize } from '../../model/Form/FormCustomize';

export const itemSubscriptionForm: FormCustomize[] = [
  {
    order: 1,
    propertyName: 'vinWithoutValidation',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-4',
  },
  {
    order: 2,
    propertyName: 'itemNumber',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-5',
  },
  {
    order: 3,
    propertyName: 'search',
    style:
      'col-sm-12 col-md-12	col-lg-12 col-xl-3 justify-content-start align-items-center',
    isFilter: true,
  },
];
