<h6 class="d-flex justify-content-end" (click)="confirmSelection()">
  <mat-icon>close</mat-icon>
</h6>
<h2 mat-dialog-title class="d-flex justify-content-center"><b>{{'moreFeedback'|translate}}</b></h2>
<mat-dialog-content class="p-2">
  <span class="d-flex justify-content-center">{{'feedback'|translate}}</span>
  @if (source) {
    <span class="d-flex justify-content-center sub-text">{{source== 'Hub'?('feedbackPopupDisclaimerServiceBox'|translate):('feedbackPopupDisclaimerLinkentry'|translate)}}</span>
  }
  <div class="vin-list-container">
    <mat-form-field appearance="outline" class="col-12">
      <textarea matInput [(ngModel)]="feedback"></textarea>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center p-2">
  <button mat-raised-button type="submit" class="col-auto d-flex justify-content-center align-items-center button"
    (click)="confirmSelection()" [disabled]="feedback.length > 750">
    {{'send'|translate}}
  </button>
</mat-dialog-actions>