import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrl: './feedback-popup.component.scss'
})
export class FeedbackPopupComponent {
  public feedback: string = "";
  public source!: string;

  constructor(
    public dialogRef: MatDialogRef<FeedbackPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) {
    this.source = data;
  }

  confirmSelection() {
    this.dialogRef.close(this.feedback);
  }
}
