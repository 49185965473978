import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { FooterComponent } from './library/common/footer/footer.component';
import { HeaderComponent } from './library/common/header/header.component';
import { DocumentCardComponent } from './library/document-card/document-card.component';
import { FeedbackComponent } from './library/feedback/feedback.component';
import { FormComponent } from './library/form/form.component';
import { ProfileComponent } from './library/profile/profile.component';
import { VinPopupComponent } from './library/vin-popup/vin-popup.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { TestPageComponent } from './pages/test/test-page.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatOptionModule } from '@angular/material/core';
import { AppRoutingModule } from './app.routing.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { TruncateScorePipe } from './pipe/truncate-score.pipe';
import { ErrorPopupComponent } from './popup/error-popup/error-popup.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageSelectionComponent } from './library/language-selection/language-selection.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import {AsyncPipe, DatePipe, NgOptimizedImage} from '@angular/common';
import { DocumentPopupComponent } from './popup/document-popup/document-popup.component';
import { FeedbackPopupComponent } from './popup/feedback-popup/feedback-popup.component';
import { DocumentSearchComponent } from './pages/document-search/document-search.component';
import { ItemSubscriptionComponent } from './pages/item-subscription/item-subscription.component';
import { ItemSubscriptionResultsComponent } from './library/item-subscription-results/item-subscription-results.component';
import {MatCheckbox} from "@angular/material/checkbox";
import {MatPaginatorModule} from "@angular/material/paginator";
import { DocumentVinComponent } from './popup/document-vin/document-vin.component';
import {MassivePageComponent} from "./pages/massive-page/massive-page.component";
import {GenericTableComponent} from "./library/generic-table/generic-table.component";
import {
  MatCell, MatCellDef,
  MatColumnDef,
  MatHeaderCell,
  MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef,
  MatRow, MatRowDef,
  MatTable
} from "@angular/material/table";
import {MatSlideToggle} from "@angular/material/slide-toggle";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, ScrollingModule} from "@angular/cdk/scrolling";
import {MassiveUploadPopupComponent} from "./popup/massive-upload-popup/massive-upload-popup.component";
import {ApiCallResultPopupComponent} from "./popup/api-call-result-popup/api-call-result-popup.component";
import {MatProgressBar} from "@angular/material/progress-bar";
import { DragDirective } from './directives/dragDrop.directive';
// Function to create the TranslateHttpLoader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DocumentCardComponent,
    DocumentPopupComponent,
    FeedbackComponent,
    FeedbackPopupComponent,
    FormComponent,
    ProfileComponent,
    VinPopupComponent,
    HomepageComponent,
    TestPageComponent,
    ErrorPageComponent,
    TruncateScorePipe,
    ErrorPopupComponent,
    LanguageSelectionComponent,
    DocumentSearchComponent,
    ItemSubscriptionComponent,
    ItemSubscriptionResultsComponent,
    DocumentVinComponent,
    MassivePageComponent,
    GenericTableComponent,
    MassiveUploadPopupComponent,
    ApiCallResultPopupComponent,
    DragDirective
  ],
  imports: [
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatDialogModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatPaginatorModule,
    BrowserModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatOptionModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatTabsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatCheckbox,
    MatHeaderRow,
    MatRow,
    MatCell,
    MatHeaderCell,
    MatTable,
    MatColumnDef,
    MatSlideToggle,
    MatRadioGroup,
    MatRadioButton,
    MatHeaderCellDef,
    NgOptimizedImage,
    MatCellDef,
    MatHeaderRowDef,
    MatRowDef,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    ScrollingModule,
    MatProgressBar
  ],
  providers: [
    TruncateScorePipe,
    AsyncPipe,
    DatePipe
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
