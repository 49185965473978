<div class="container-fluid popup-container">
    <div class="h5 mt-2 d-flex flex-column align-items-end">
        <label mat-dialog-close>X</label>
    </div>
    <div class="row d-flex justify-content-center text-center">
        <div class="col-12">
            <mat-icon class="result_icon" svgIcon="done_icon"></mat-icon>
        </div>
        <div class="col-12 h6 error-text">
            <label>Success</label>
        </div>
        <div class="h2">
            <label>{{message}}</label>
        </div>
        <div *ngIf="faultyIds != null">
            <label>Except for the following ticket code/s : {{faultyIds}}</label>
        </div>
    </div>
    <div class="mt-4 button-container">
        <button mat-button class="col-5 similarity-button" mat-dialog-close>CLOSE</button>
    </div>
</div>