import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {environment} from "../../../environments/environment";
import {ApiService} from "../../services/api/api.service";
import _ from 'lodash';

@Component({
  selector: 'generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent implements OnInit, AfterViewInit, OnChanges {
  // @Input() ELEMENT_DATA!: ItemProposalTable['table'];//| AgentTable['table'];
  @Input() ELEMENT_DATA!: any['table'];
  @Input() defaultSorting!: {
    index: number;
    sortValue: string;
    colName: string;
  };
  @Input() displayedColumns!: { [key: string]: any };
  @Input() inactiveList: [] = [];
  @Output() changeStatusEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() S3Event: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteItemEvent: EventEmitter<number> = new EventEmitter<number>();
  @ViewChildren(MatSelect) matSelects!: QueryList<MatSelect>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild('selectControl') selectControl!: MatSelect;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('allSelected') private allSelected!: MatOption;

  public headerName: any;
  public columnsKeys!: Array<string>;
  public dataSource = new MatTableDataSource();
  public isSortEnabled: any;
  public previousValues: any;
  public defaultSortColumn = 1;
  public distinctValuesMap: Map<string, any[]> = new Map();

  public isConfirm: any;
  public sortValue: any;
  public counter = 0;
  public radiobutton: any;
  tableForm!: FormGroup;
  public options: any;
  public toggleAll = true;
  public isDataDisplayed = true;
  public temp: any;
  public firstInit: boolean = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private service: ApiService,
    private dialog: MatDialog
  ) {
    this.matIconRegistry.addSvgIcon(
      'review',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/add-to-my-items.svg'
      )
    );
    this.matIconRegistry.addSvgIcon(
      'not-review',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/added-to-my-items.svg'
      )
    );
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.formArray();
    this.columnsKeys = Object.keys(this.displayedColumns);

    this.updateMapDistinctValues();
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);

    this.dataSource.paginator = this.paginator;
    this.isConfirm = true;
    this.isDataDisplayed =
      this.dataSource.filteredData.length > 0 ? true : false;
    this.hideColumns();
    if (this.defaultSorting && this.ELEMENT_DATA.length != 0) {
      this.table_dropDown()
        .at(this.defaultSorting.index)
        .get('sort')
        ?.setValue(true);
      this.table_dropDown()
        .at(this.defaultSorting.index)
        .get('sortValue')
        ?.setValue(this.defaultSorting.sortValue);
      this.confirm(this.defaultSorting.index, this.defaultSorting.colName);
    }
    this.previousValues = this.table_dropDown().value;
  }

  updateMapDistinctValues() {
    this.columnsKeys.forEach((key: string) => {
      this.distinctValuesMap.set(key, this.separateDistinctValues(key));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    /** Reassigning the cloumns so that we can get the full list of array then
     * remove the elements from the list otherwise everytime
     * there will be change original array will get reduced
     */

    if (changes['inactiveList']) {
      this.ngOnInit();
      this.ngAfterViewInit();
    }
    if (changes['ELEMENT_DATA']) {
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;
      this.isConfirm = true;
      this.isDataDisplayed =
        this.dataSource.filteredData.length > 0 ? true : false;

      // Fix for inactive unset in input
      if (!this.tableForm) {
        this.formArray();
      }

      this.table_dropDown().value.forEach((data: any) => {
        if (data.sort) {
          this.tableSorting(Object.keys(data)[0], data.sortValue);
        }
      });
      this.table_dropDown().controls.forEach((element: any) => {
        this.columnsKeys.forEach((key: string) => {
          if (element.controls.hasOwnProperty(key)) {
            element.controls.options.value = this.getDistinctValues(key);
          }
        });
      });
      this.filterData();
      this.ngAfterViewInit();
    }
  }

  formArray() {
    this.tableForm = this.fb.group({
      table_dropDown: this.fb.array(<any>[]),
    });
  }

  shouldDisplayFormField(colName: string): boolean {
    return ![
      'view',
      'setReview',
      'setNotReview',
      'fileName',
      'viewDeleted',
      'viewItem',
    ].includes(colName);
  }

  shouldDisplayOption(select: any, index: number): boolean {
    const searchValue = this.table_dropDown()
      .at(index)
      .get('search')
      ?.value?.toLowerCase();
    return (
      !searchValue ||
      (typeof select === 'string' && select.toLowerCase().includes(searchValue))
    );
  }

  formatSelectOption(select: any, colName: string): string {
    if (colName === 'updateStatus') {
      return select === true
        ? 'Completed'
        : select === false
        ? 'In progress'
        : select;
    }
    return select;
  }

  //Form array control function, it will create table_dropdown function as from array
  table_dropDown(): FormArray {
    return this.tableForm.get('table_dropDown') as FormArray;
  }

  //This method is used to generate dynamic formcontrols for formarray.
  newField(elem: any, options: any): FormGroup {
    return this.fb.group({
      [elem]: [],
      sort: false,
      sortValue: 'asc',
      sortEnabled: false,
      search: '',
      options: [[...options]],
    });
  }

  //This method is used to display certain columns based on the values selected from tablesettings popup.
  hideColumns() {
    this.inactiveList.forEach((AL: string) => {
      let index = this.columnsKeys.findIndex((a) => {
        return this.displayedColumns[a] == AL;
      });
      if (index !== -1) {
        this.columnsKeys.splice(index, 1);
      }
    });
    const qualityCheckIndex = this.columnsKeys.indexOf('qualityCheck');
    if (qualityCheckIndex !== -1) {
      this.columnsKeys.splice(qualityCheckIndex, 1);
      this.columnsKeys.push('qualityCheck');
    }
    const kpiMonitoringIndex = this.columnsKeys.indexOf('kpiMonitoring');
    if (kpiMonitoringIndex !== -1) {
      this.columnsKeys.splice(kpiMonitoringIndex, 1);
      this.columnsKeys.push('kpiMonitoring');
    }
    const remainingViewIndex = this.columnsKeys.indexOf('view');
    if (remainingViewIndex !== -1) {
      this.columnsKeys.splice(remainingViewIndex, 1);
      this.columnsKeys.push('view');
    }
    //this.displayedColumnsKeys = Object.keys(this.displayedColumns);
    this.createFormArray();
  }
  selectAll(colName: string, i: number) {
    let arrayControl = this.tableForm.get('table_dropDown') as FormArray;
    let item = arrayControl.at(i) as FormGroup;
    if (this.toggleAll) {
      item.controls[colName].patchValue([
        ...this.separateDistinctValues(colName),
        0,
      ]);
    } else {
      item.controls[colName].patchValue([]);
    }

    this.toggleAll = !this.toggleAll;
  }

  /** This function will check if All option is selected then deselect it
   * Otherwise check if length of selected values are equal to given values then select the All option*/
  deselectAll(colName: string, i: number) {
    let arrayControl = this.tableForm.get('table_dropDown') as FormArray;
    let item = arrayControl.at(i) as FormGroup;

    let indexOfAll = item.controls[colName].value.findIndex((a: any) => {
      return a === 0;
    });

    if (indexOfAll > -1) {
      item.controls[colName].value.splice(indexOfAll, 1);
    }

    if (item.controls[colName].value.length == item.value.options.length) {
      this.toggleAll = false;
      item.controls[colName].patchValue([...item.controls[colName].value, 0]);
    } else {
      this.toggleAll = true;
      item.controls[colName].patchValue([...item.controls[colName].value]);
    }
  }

  /**
   * Each time you close the dropdown, you filter the table data by each form array values
   */
  filterData() {
    this.previousValues = this.table_dropDown().value;
    let filteredData = this.ELEMENT_DATA;
    Object.keys(this.displayedColumns).forEach((element) => {
      let ticketNumberControl = this.table_dropDown()
        .controls.find((control) => control.get(element))
        ?.get(element)?.value;
      if (ticketNumberControl?.length > 0) {
        filteredData = filteredData.filter((item: { [key: string]: any }) => {
          return ticketNumberControl?.some((option: any) => {
            if (item[element] != null && option != null) {
              return item[element].toString().includes(option.toString());
            } else {
              return false;
            }
          });
        });
      }
    });
    this.dataSource.data = filteredData;
    this.dataSource.paginator = this.paginator;
    this.isDataDisplayed =
      this.dataSource.filteredData.length > 0 ? true : false;
  }

  /**
   * Closes the mat select when clicked on confirm button
   * @param id dinamic id of the mat select to close
   */
  closeSelect(id: string, i: any) {
    if (this.table_dropDown().value != this.previousValues) {
      this.table_dropDown().setValue(this.previousValues);
    }
    // if(!this.isConfirm){
    //   this.table_dropDown().at(i).get('sort')?.setValue(this.table_dropDown().at(i).get('sortEnabled')?.value)
    // }
    const matSelect = this.matSelects.find((select) => select.id === id);
    if (matSelect) {
      matSelect.close();
    }
    this.toggleAll = true;
  }

  //This method is used to trigger the toggle button event.
  toggleChanges(event: any, i: number) {
    this.isConfirm = false;
    if (event.checked) {
      this.table_dropDown().at(i).get('sortValue')?.setValue('asc');
    }
  }
  /**
   * Creates the form array based on table columns .
   *  pushes the new filed funtion values to the table dropdown formarray.
   */
  createFormArray() {
    const arr = <FormArray>this.tableForm.controls['table_dropDown'];
    arr.controls = [];
    this.columnsKeys.forEach((element) => {
      this.table_dropDown().push(
        this.newField(element, this.getDistinctValues(element))
      );
    });
  }
  /**
   * Method to sort the table data in ascending or descending order
   * @param name is used to sort the particulr column and @param order is used for particulr order either ascending or descending
   * @param order
   */
  tableSorting(name: any, order: any) {
    this.ELEMENT_DATA = _.orderBy(this.ELEMENT_DATA, [name], [order]);
    this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
    this.dataSource.paginator = this.paginator;
  }
  /**
   * Method to check the data type of the value
   * @param value is used to check the type of value
   */
  typecheck(value: any) {
    return typeof value;
  }

  /**
   * Method to find the  distinct values in table data for each row.
   * @param element is used to find the distinct values in each row.
   */
  getDistinctValues(element: any) {
    let options: any;
    options = [...new Set(this.ELEMENT_DATA.map((item: any) => item[element]))];
    return options;
  }

  deleteItemClick(itemNumber: number) {
    this.deleteItemEvent.emit(itemNumber);
  }

  separateDistinctValues(colName: string): any[] {
    let options: any;
    options = [
      ...new Set(
        this.dataSource.filteredData.map((item: any) => item[colName])
      ),
    ];

    if (
      colName === 'ticketIdList' ||
      colName === 'ecuFault' ||
      colName === 'fqt'
    ) {
      options = Array.from(
        new Set(
          options.flatMap((item: any) => {
            if (item != null && item !== '' && item !== undefined) {
              if (item.includes(',')) {
                return item.split(',');
              } else {
                return [item];
              }
            }
            return [];
          })
        )
      );
    }

    return options;
  }

  /**
   * Clicking on confirm to filter the data and sort the data based on values selected in dropdown
   * @param index ,@param colName is used to sort and filter the particular table row data.
   */
  confirm(index: any, colName: any) {
    this.isConfirm = true;
    this.radiobutton = true;
    // this.table_dropDown().at(index).get('sortEnabled')?.setValue(this.table_dropDown().at(index).get('sort')?.value)
    if (this.table_dropDown().at(index).get('sort')?.value === true) {
      this.tableSorting(
        colName,
        this.table_dropDown().at(index).get('sortValue')?.value
      );
      this.table_dropDown().controls.forEach((element: any, i) => {
        if (i != index) {
          this.table_dropDown().at(i).get('sort')?.setValue(false);
        }
      });
    } else {
      this.tableSorting('remainingDays', 'asc');
      this.table_dropDown()
        .at(this.defaultSortColumn)
        .get('sort')
        ?.setValue(true);
      this.table_dropDown()
        .at(this.defaultSortColumn)
        .get('sortValue')
        ?.setValue('asc');
    }
    this.table_dropDown().at(index).get('search')?.setValue('');
    this.filterData();
    this.closeSelect(colName, index);
    this.updateMapDistinctValues();
  }

  generateS3Event(id: number) {
    this.S3Event.emit(id);
  }


  isTextTruncated(text: string): boolean {
    const element = document.getElementById(text);
    return element!?.scrollHeight > element!?.clientHeight;
  }
  shouldShowTooltip(colName: string): boolean {
    const columnsWithTooltip = [
      'customerEffect',
      'ticketIdList',
      'ecuFault',
      'fqt',
    ];
    return columnsWithTooltip.includes(colName);
  }

  returnValue(colName: string, element: any) {
    if (colName === 'fqt') return element[colName].replace(/,/g, ',<br>');
    else return element[colName];
  }
}
