import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Clipboard } from '@angular/cdk/clipboard';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
/**
 * Massive similarity upload popup for successful upload
 * Also displays faulty ticket codes that failed the upload
 */
@Component({
  selector: 'api-call-result-popup',
  templateUrl: './api-call-result-popup.component.html',
  styleUrls: ['./api-call-result-popup.component.scss'],
})
export class ApiCallResultPopupComponent implements OnInit {
  public message: any;
  public faultyIds: any;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public clipboard: Clipboard,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.matIconRegistry.addSvgIcon(
      'done_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/done_icon.svg'
      )
    );
  }

  @HostListener('document:keydown.enter', ['$event'])
  onEnterKeydown(event: KeyboardEvent) {
    event.stopPropagation();
  }

  /**
   When page opens this function excutes and assign the values to particular variable and displays properly on page.
   */
  ngOnInit(): void {
    this.message = this.data.message;
    this.faultyIds = (this.data.faultyIds) ? this.data.faultyIds : null;
  }
}
