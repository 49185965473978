<div class="page">
  <div class="container-fluid">
    <div class="row m-4">
      <div class="col">
        <h2 class="ms-3">{{ "itemSubscriptionTitle" | translate }}</h2>
      </div>
    </div>
  </div>
  <div class="row m-4">
    <app-form
      *ngIf="formGroup"
      [searchSpinner]="searchSpinner"
      (submitEvent)="askDTA()"
      [formGroup]="formGroup"
      [formCustomize]="formCustomize"
      [hasVinBeenValidated]="true"
    ></app-form>
  </div>
  @if (noDocuments && (formGroup.get('vinWithoutValidation')!.value != '' ||
  formGroup.get('itemNumber')!.value != '')) {
  <div class="row ps-5 pe-5">
    <mat-error >
      {{ "itemSubscriptionNoResults" | translate }}
    </mat-error>
  </div>
  } @if (allDocuments) {
  <div class="row m-4">
    <app-item-subscription-results
      [documents]="allDocuments.documents"
    ></app-item-subscription-results>
  </div>
  }
</div>
