import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VinPopupComponent } from '../../library/vin-popup/vin-popup.component';
import {
  brandCodeMap,
  ChatGPTOutput,
  Config,
  Document,
  DocumentData,
  OVApplicability,
  OVDocumentInput,
  XFApplicability,
  XFDocumentInput,
  XPApplicability,
  XPDocumentInput,
} from '../../model/ChatGPT/documents/documentSearch';
import { ValidateReq } from '../../model/CinAPI/ValidateReq';
import { xFValidationOutput } from '../../model/CinAPI/xF/xFValidationOutput';
import { xPValidationOutput } from '../../model/CinAPI/xP/xPValidationOutput';
import { FormCustomize } from '../../model/Form/FormCustomize';
import { ErrorPopupComponent } from '../../popup/error-popup/error-popup.component';
import { ApiService } from '../../services/api/api.service';
import { documentSearchForm } from './document-search-form';
import {
  alphanumericSpecialValidator,
  alphanumericValidator,
  dtcValidator,
  isVINValidated,
  numericValidator,
} from '../../library/form/form.component';

@Component({
  selector: 'app-document-search',
  templateUrl: './document-search.component.html',
  styleUrl: './document-search.component.scss',
})
export class DocumentSearchComponent implements OnInit {
  public formCustomize: FormCustomize[] = documentSearchForm;
  public allDocuments!: ChatGPTOutput | null;
  public otherDocuments!: Document[] | null;
  public singleDocument!: Document | null;
  public visibleDocuments!: Document[] | null;
  private source!: string;
  private token!: string;
  private tokenParam!: string;
  private userRole!: string;
  public formGroup!: FormGroup;
  public hasVinBeenValidated: boolean = false;
  public validateSpinner: boolean = false;
  public searchSpinner: boolean = false;
  public previousVin: string = '';
  public userId!: string;
  public vinData!: xFValidationOutput | xPValidationOutput | undefined;
  private callBackUrl: string = '';
  public noDocuments: boolean = false;
  private brandList = [];

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private dialog: MatDialog,
    private router: Router,
    public translate: TranslateService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      const token = params['token'];
      const tokenParam = params['tokenParam'];
      const source = params['Source'];

      this.translate.use(sessionStorage.getItem('language') ?? 'en-GB');
      if (!sessionStorage.getItem('sessionId')) {
        const queryParams = this.route.snapshot.queryParams;
        this.router.navigate(['digitaltechassistance/request/search'], {
          queryParams,
        });
      }

      if (source) {
        this.source = source;
      }
      if (token) {
        this.token = token;
      }
      if (
        (source == 'LinkEntry' && tokenParam) ||
        (source == 'POI-XP' && tokenParam)
      ) {
        sessionStorage.setItem('tokenParam', tokenParam);
      }
      if (source === 'POI-XF') {
        this.token = tokenParam;
        sessionStorage.setItem('tokenParam', tokenParam);
      }

      console.log(this.source, [this.token, tokenParam]);

      this.api
        .jwtValidate(this.source, [
          this.token,
          this.source == 'LinkEntry' ||
          this.source == 'POI-XP' ||
          this.source == 'POI-XF'
            ? tokenParam
            : null,
        ])
        .subscribe(
          (response: any) => {
            switch (this.source) {
              case 'Hub':
                this.userId = response.userId;
                this.callBackUrl = response.fdzCallbackURL;
                this.brandList = response.brandList;
                this.userRole = response.userRole;
                break;
              case 'LinkEntry':
                this.userId = response.UserID;
                this.userRole = response.userRole;
                break;
              case 'POI-XP':
                this.userId = response.userID;
                this.callBackUrl = response.callBackUrl;
                this.userRole = response.userRole;
                break;
              case 'POI-XF':
                this.userId = response.userID;
                this.callBackUrl = response.callBackUrl;
                this.userRole = response.userRole;
                break;
            }
            this.initializeForm();
            this.getSubscribedItemsNumber();
          },
          (error: any) => {}
        );
    });
  }

  getSubscribedItemsNumber() {
    this.api.getSubscribedItemsNumber(this.userId).subscribe((resp: any) => {
      sessionStorage.setItem('itemsSubscribed', JSON.stringify(resp.items));
    });
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      documentType: [
        'Technical Service Bulletin [Service Box]',
        Validators.required,
      ],
      documentReference: ['', Validators.required],
      vin: [
        '',
        [Validators.required, isVINValidated(this.hasVinBeenValidated)],
      ],
      model: [{ value: null, disabled: true }],
    });

    this.updateDocumentReferenceValidators(
      'Technical Service Bulletin [Service Box]'
    );

    this.formGroup.get('vin')?.valueChanges.subscribe((change: any) => {
      if (change !== this.previousVin) {
        this.validateSpinner = false;
        this.previousVin = change;
        this.formGroup.get('model')?.patchValue(null);
        this.hasVinBeenValidated = false;
        this.vinData = undefined;
        this.formGroup.get('vin')?.updateValueAndValidity();
      }
      this.previousVin = change;
    });

    this.formGroup
      .get('documentType')
      ?.valueChanges.subscribe((documentType: string) => {
        console.log('validator changes: ', documentType);

        this.updateDocumentReferenceValidators(documentType);
      });
  }

  updateDocumentReferenceValidators(documentType: string) {
    const documentReferenceControl = this.formGroup.get('documentReference');
    const vinReferenceControl = this.formGroup.get('vin');

    vinReferenceControl!.setErrors(null);

    if (documentReferenceControl && vinReferenceControl) {
      switch (documentType) {
        case 'Item [iSolve]':
          documentReferenceControl.setValidators([
            Validators.required,
            numericValidator(),
          ]);
          vinReferenceControl.setValidators([]);
          break;
        case 'Technical Service Bulletin [Service Box]':
          documentReferenceControl.setValidators([
            Validators.required,
            alphanumericValidator(),
          ]);
          vinReferenceControl.setValidators([Validators.required]);
          break;
        case 'Service News Technical Service Bulletin [eLearn]':
          documentReferenceControl.setValidators([
            Validators.required,
            alphanumericSpecialValidator(),
          ]);
          vinReferenceControl.setValidators([Validators.required]);
          break;
        case 'Teseo Bullettin [eLearn]':
          documentReferenceControl.setValidators([
            Validators.required,
            alphanumericSpecialValidator(),
          ]);
          vinReferenceControl.setValidators([Validators.required]);
          break;
        case 'DTC [Service Box]':
          documentReferenceControl.setValidators([
            Validators.required,
            dtcValidator(),
          ]);
          vinReferenceControl.setValidators([Validators.required]);
          break;
        case 'DTC [eLearn]':
          documentReferenceControl.setValidators([
            Validators.required,
            dtcValidator(),
          ]);
          vinReferenceControl.setValidators([Validators.required]);
          break;
        default:
          documentReferenceControl.setValidators([Validators.required]);
      }

      // Trigger validation update
      documentReferenceControl.updateValueAndValidity();
      vinReferenceControl.updateValueAndValidity();

      console.log(
        'documentReferenceControl errors:',
        documentReferenceControl.errors
      );
      console.log('vinReferenceControl errors:', vinReferenceControl.errors);
    }
  }

  validateVINVIS(): Promise<void> {
    this.validateSpinner = true;
    let vinController = this.formGroup.get('vin');

    let req: ValidateReq = {
      vin: vinController?.value.length === 17 ? vinController?.value : null,
      vis: vinController?.value.length === 8 ? vinController?.value : null,
      dealerLanguage: this.translate.currentLang.replace('-', '_'),
    };

    return new Promise((resolve) => {
      if (req.vin) {
        this.validateVIN(req).then(() => {
          this.validateSpinner = false;
          resolve();
        });
      } else if (req.vis) {
        this.validateVIS(req).then(() => {
          this.validateSpinner = false;
          resolve();
        });
      } else {
        this.validateSpinner = false;
        resolve();
      }
    });
  }

  validateVIN(req: ValidateReq): Promise<void> {
    return new Promise((resolve, reject) => {
      let vinController = this.formGroup.get('vin');

      this.api.validateVinVisAPI(req, 'Hub').subscribe(
        (respHub: any) => {
          if (respHub && respHub.length === 1) {
            this.formGroup.patchValue({ vin: respHub[0].vin });
            this.formGroup.patchValue({
              model:
                respHub[0].pcdData[0]?.brand +
                ' ' +
                respHub[0].pcdData[0]?.ESAModelDescription +
                ' ' +
                respHub[0].pcdData[0]?.ESAModelYear +
                ' ' +
                respHub[0].pcdData[0]?.engineDescription,
            });
            this.previousVin = respHub[0].vin;
            this.vinData = respHub[0];
            if (
              (this.vinData as xPValidationOutput).pcdData[0].listElectronics &&
              (this.vinData as xPValidationOutput).pcdData[0].listElectronics
                .length > 0
            ) {
              (this.vinData as xPValidationOutput).pcdData[0].listElectronics =
                (
                  this.vinData as xPValidationOutput
                ).pcdData[0].listElectronics.map((element: string) =>
                  element.trim()
                );
            }

            vinController?.setErrors(null);
            this.hasVinBeenValidated = true;
            this.validateSpinner = false;
            resolve(); // Resolving after Hub validation
          } else {
            callHubApi();
          }
        },
        (errorLink: any) => {
          callHubApi();
        }
      );

      const callHubApi = () => {
        this.api.validateVinVisAPI(req, 'LinkEntry').subscribe(
          (respLink: any) => {
            if (respLink && respLink.exfcaData.length === 1) {
              this.formGroup.patchValue({
                vin: respLink.exfcaData[0]?.chassisData.vin,
              });
              this.formGroup.patchValue({
                model: respLink.exfcaData[0]?.vehicleDescription.carline,
              });
              this.previousVin = respLink.exfcaData[0]?.chassisData.vin;
              this.vinData = respLink;
              vinController?.setErrors(null);
              this.hasVinBeenValidated = true;
              this.validateSpinner = false;
              resolve(); // Resolving after LinkEntry validation
            } else {
              this.validateSpinner = false;
              this.hasVinBeenValidated = false;
              reject(new Error('VIN validation failed'));
            }
          },
          (errorHub: any) => {
            this.validateSpinner = false;
            this.hasVinBeenValidated = false;
            reject(new Error('VIN validation failed'));
          }
        );
      };
    });
  }

  validateVIS(req: ValidateReq): Promise<void> {
    return new Promise((resolve, reject) => {
      let multipleVin: string[] = [];

      this.api.validateVinVisAPI(req, 'Hub').subscribe(
        (respHub: any) => {
          if (respHub && respHub.length !== 0 && respHub.length !== 1) {
            const vins = respHub
              .map((element: any) => element.vin)
              .filter((vin: string) => vin !== '');
            vins.forEach((vin: string) => multipleVin.push(vin));
          } else if (respHub && respHub.length === 1) {
            multipleVin.push(respHub[0].vin);
          }

          callHubApi();
        },
        (errorLink: any) => {
          callHubApi();
        }
      );

      const callHubApi = () => {
        this.api.validateVinVisAPI(req, 'LinkEntry').subscribe(
          (respLink: any) => {
            if (
              respLink &&
              respLink.exfcaData.length !== 0 &&
              respLink.exfcaData.length !== 1
            ) {
              const vins = respLink.exfcaData
                .map((element: any) => element.vin)
                .filter((vin: string) => vin !== '');
              vins.forEach((vin: string) => multipleVin.push(vin));
            } else if (respLink && respLink.exfcaData.length === 1) {
              multipleVin.push(respLink.exfcaData[0]?.chassisData.vin);
            }

            if (multipleVin.length === 1) {
              this.formGroup.patchValue({ vin: multipleVin[0] });
              resolve(); // Single VIN resolved
            } else if (multipleVin.length !== 0) {
              this.openVinPopup(multipleVin);
              resolve(); // Popup opened, resolve
            } else {
              this.validateSpinner = false;
              this.hasVinBeenValidated = false;
              reject(new Error('VIS validation failed'));
            }
          },
          (errorHub: any) => {
            this.validateSpinner = false;
            this.hasVinBeenValidated = false;
            reject(new Error('VIS validation failed'));
          }
        );
      };
    });
  }

  openVinPopup(possibleVINs: string[]) {
    this.validateSpinner = false;
    this.hasVinBeenValidated = false;
    this.formGroup.get('vin')?.updateValueAndValidity();

    const dialogRef = this.dialog.open(VinPopupComponent, {
      width: '450px',
      disableClose: true,
      data: {
        possibleVINs: possibleVINs,
        vin: this.formGroup.get('vin')?.value,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.previousVin = result;
        this.formGroup.get('vin')?.patchValue(result);
        this.validateVINVIS();
      } else {
        this.hasVinBeenValidated = false;
        this.formGroup.get('vin')?.updateValueAndValidity();
      }
    });
  }

  askDTA(event: any) {
    this.allDocuments = null;
    this.otherDocuments = null;
    this.singleDocument = null;
    this.visibleDocuments = null;
    this.noDocuments = false;
    const documentTypeMap: { [key: string]: string } = {
      'Item [iSolve]': 'Item - iSolve',
      'Technical Service Bulletin [Service Box]': 'TSB - Service Box',
      'Service News Technical Service Bulletin [eLearn]':
        'Service News - eLearn',
      'Teseo Bullettin [eLearn]': 'Teseo Bullettin - eLearn',
      'DTC [eLearn]': 'DTC - eLearn',
    };
    // Map the documentType value to the corresponding string
    const mappedDocumentType =
      documentTypeMap[this.formGroup.get('documentType')!.value] ||
      this.formGroup.get('documentType')!.value;
    const documentData: DocumentData = {
      documentType: mappedDocumentType,
      documentReference: this.formGroup.get('documentReference')!.value,
      vin: event.vin ?? '',
      model: this.formGroup.get('model')?.value ?? '',
      brand:
        this.source === 'Hub'
          ? this.brandList
          : this.vinData && (this.vinData as xFValidationOutput).exfcaData
          ? [
              (this.vinData as xFValidationOutput).exfcaData[0]
                .vehicleDescription.brandCode,
            ]
          : [],
    };

    const config: Config = {
      sessionId: sessionStorage.getItem('sessionId') ?? '',
      conversationId: sessionStorage.getItem('conversationId') ?? '',
      language_iso_code: this.translate.currentLang,
      portalType: this.source,
      userRole: this.userRole,
    };

    let req: XFDocumentInput | XPDocumentInput | OVDocumentInput;

    if (this.vinData && 'pcdData' in this.vinData && !this.vinData.isOVLegacy) {
      let xPApplicability: XPApplicability = {
        lcdv24: '',
        listLcdvOvip: [],
        modelYear: 0,
        itemToken:
          sessionStorage.getItem('jwt') != 'undefined'
            ? sessionStorage.getItem('jwt')!
            : '',
        PCDAPVPR: '',
        listElectronics: [],
        poiToken:
          this.source == 'POI-XP' || this.source == 'POI-XF' ? sessionStorage.getItem('tokenParam') : null,
      };

      if (this.formGroup.get('vin')!.value != '') {
        xPApplicability = {
          lcdv24:
            (this.vinData as xPValidationOutput).pcdData[0].lcdv24.slice(
              0,
              4
            ) ?? null,
          listLcdvOvip: this.getListLcdvOvip() ?? null,
          modelYear:
            Number(
              (
                this.vinData as xPValidationOutput
              ).pcdData[0].vehicleBuildDate.slice(0, 4)
            ) ?? null,
          itemToken:
            sessionStorage.getItem('jwt') != 'undefined'
              ? sessionStorage.getItem('jwt')!
              : '',
          PCDAPVPR:
            (this.vinData as xPValidationOutput).pcdData[0].PCDAPVPR ?? null,
          listElectronics:
            (this.vinData as xPValidationOutput).pcdData[0].listElectronics ??
            [],
          poiToken:
            this.source == 'POI-XP' || this.source == 'POI-XF'
              ? sessionStorage.getItem('tokenParam')
              : null,
        };
      }

      req = {
        config: config,
        documentData: documentData,
        applicability: xPApplicability,
        linkToDocument: this.callBackUrl,
        userId: this.userId,
        vinType: 'xP',
      } as XPDocumentInput;
    } else if (
      this.vinData &&
      'pcdData' in this.vinData &&
      this.vinData.isOVLegacy
    ) {
      let ovApplicability: OVApplicability = {
        lcdv24: '',
        itemToken:
          sessionStorage.getItem('jwt') != undefined
            ? sessionStorage.getItem('jwt')!
            : '',
        poiToken:
          this.source == 'POI-XP' || this.source == 'POI-XF' ? sessionStorage.getItem('tokenParam') : null,
      };

      if (this.formGroup.get('vin')!.value != '') {
        ovApplicability = {
          lcdv24:
            (this.vinData as xPValidationOutput).pcdData[0].lcdv24.slice(
              0,
              4
            ) ?? null,
          itemToken:
            sessionStorage.getItem('jwt') != undefined
              ? sessionStorage.getItem('jwt')!
              : '',
          poiToken:
            this.source == 'POI-XP' || this.source == 'POI-XF'
              ? sessionStorage.getItem('tokenParam')
              : null,
        };
      }

      req = {
        config: config,
        documentData: documentData,
        applicability: ovApplicability,
        linkToDocument: this.callBackUrl,
        userId: this.userId,
        vinType: 'OVLegacy',
      } as XPDocumentInput;
    } else {
      let xFApplicability: XFApplicability = {
        mvs: '',
        brandcode: '',
        itemToken:
          sessionStorage.getItem('jwt') != undefined
            ? sessionStorage.getItem('jwt')!
            : '',
        modelYear: 0,
        poiToken:
          this.source == 'POI-XP'|| this.source == 'POI-XF' ? sessionStorage.getItem('tokenParam') : null,
      };

      if (this.formGroup.get('vin')!.value != '') {
        xFApplicability = {
          mvs:
            (this.vinData as xFValidationOutput).exfcaData[0].vehicleDescription
              .mvs ?? null,
          brandcode:
            brandCodeMap.get(
              (this.vinData as xFValidationOutput).exfcaData[0]
                .vehicleDescription.brandCode
            )! ?? null,
          itemToken:
            sessionStorage.getItem('jwt') != undefined
              ? sessionStorage.getItem('jwt')!
              : '',
          modelYear:
            Number(
              (
                this.vinData as xFValidationOutput
              ).exfcaData[0].vehicleDescription.vehicleBuildDate.slice(-4)
            ) ?? null,
          poiToken:
            this.source == 'POI-XP' || this.source == 'POI-XF'
              ? sessionStorage.getItem('tokenParam')
              : null,
        };
      }
      req = {
        config: config,
        documentData: documentData,
        applicability: xFApplicability,
        userId: this.userId,
        vinType: 'xF',
      } as XFDocumentInput;
    }

    if (req) {
      this.searchSpinner = true;
      this.api.documentSearch(req).subscribe(
        (resp: any) => {
          if (resp.documents.length == 0) this.noDocuments = true;

          this.allDocuments = resp;
          this.allDocuments!.documents.forEach((document) => {
            document.feedback = null;
          });

          const documents = this.allDocuments!.documents;
          if (this.formGroup.get('documentType')!.value.includes('DTC')) {
            this.otherDocuments = documents;
            this.visibleDocuments = this.otherDocuments;
          } else {
            this.singleDocument = documents[0];
          }
          sessionStorage.setItem('conversationId', resp.conversationId);
          this.searchSpinner = false;
        },
        (err: any) => {
          let error = {
            statusCode: err.error.statusCode,
            url: err.error.url.replace('uri=/aiengine/api/', ''),
            details: err.error.details,
            message: 'Error with ChatGpt api',
          };
          this.errorPopup(error);
          this.allDocuments = null;
          this.otherDocuments = null;
          this.singleDocument = null;
          this.visibleDocuments = null;
          this.searchSpinner = false;
          this.noDocuments = true;
        }
      );
    } else {
      console.error('Request configuration is not defined.');
    }
  }

  async vinPopupValidate(event: string) {
    this.formGroup.get('vin')?.setValue(event);
    this.validateVINVIS().then(() => {
      this.askDTA(this.formGroup.value);
    });
  }

  errorPopup(error: any) {
    this.dialog.open(ErrorPopupComponent, {
      disableClose: true,
      data: error,
    });
  }

  getListLcdvOvip() {
    let lcdv24 = (this.vinData as xPValidationOutput).pcdData[0].lcdv24;
    let filteredList: string[] = [];

    (this.vinData as xPValidationOutput).pcdData[0].listLcdvOvip.forEach(
      (item) => {
        const firstFiveDigits = item.trim().substring(0, 5);
        filteredList.push(firstFiveDigits);
      }
    );

    if (lcdv24) {
      filteredList.push('B0A' + '0' + lcdv24.slice(0, 1));
      filteredList.push('B0B' + '0' + lcdv24.slice(1, 2));
      filteredList.push('B0C' + lcdv24.slice(2, 4));
      filteredList.push('B0D' + lcdv24.slice(4, 6));
      filteredList.push('B0E' + '0' + lcdv24.slice(6, 7));
      filteredList.push('B0F' + lcdv24.slice(7, 9));
      filteredList.push('B0G' + '0' + lcdv24.slice(9, 10));
      filteredList.push('B0H' + lcdv24.slice(10, 12));
      filteredList.push('B0J' + lcdv24.slice(12, 14));
      filteredList.push('B0K' + '0' + lcdv24.slice(14, 15));
      filteredList.push('B0L' + '0' + lcdv24.slice(15, 16));
      filteredList.push('B0M' + lcdv24.slice(16, 18));
      filteredList.push('B0N' + lcdv24.slice(18, 20));
      filteredList.push('B0P' + lcdv24.slice(20, 22));
      filteredList.push('B0R' + lcdv24.slice(22, 24));
    }

    return filteredList;
  }
}
