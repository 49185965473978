export interface XFChatGPTInput {
  config: Config;
  problemData: ProblemData;
  applicability: XFApplicability;
  userId: string;
  toolErrorCode: string | null;
}

export interface OVChatGPTInput {
  config: Config;
  problemData: ProblemData;
  applicability: OVApplicability;
  toolErrorCode: string | null;
}

export interface XPChatGPTInput {
  config: Config;
  problemData: ProblemData;
  applicability: XPApplicability;
  userId: string;
  linkToDocument: string | null;
  toolErrorCode: string | null;
}

export interface XFApplicability {
  mvs: string;
  brandcode: string;
  modelYear: number;
  itemToken: string;
  poiToken: string | null;
  applicabilityType: string | null;
}

export interface XPApplicability {
  itemToken: string;
  lcdv24: string | null;
  listLcdvOvip: string[] | null;
  modelYear: number | null;
  PCDAPVPR: string | null;
  listElectronics: string[] | null;
  poiToken: string | null;
  userId: string;
  baseUrl: string | null;
  applicabilityType: string | null;
}

export interface OVApplicability {
  lcdv24: string | null;
  itemToken: string;
  poiToken: string | null;
  baseUrl: string | null;
  userId: string;
  applicabilityType: string | null;
}

export interface ProblemData {
  problem: string;
  problemNature: string;
  vin: string;
  model: string;
  DTCs: string[];
  brand: string[];
}

export interface Config {
  sessionId: string | null;
  conversationId: string | null;
  language_iso_code: string;
  portalType: string;
  userRole: string;
}

export interface ChatGPTOutput {
  documents: Document[];
  sessionId: string;
  conversationId: string;
  searchHistory: number;
}

export interface Document {
  title: string;
  url: string;
  score: number;
  summary: string;
  customerSymptoms: string;
  type: string;
  feedback: boolean | null;
  item_solution_type: string;
  item_solution_type_translated: string;
}

export const brandCodeMap: Map<string, string> = new Map([
  ['CY', '55'],
  ['DG', '56'],
  ['JE', '57'],
  ['RM', '58'],
  ['AR', '83'],
  ['LA', '70'],
  ['AH', '66'],
  ['FO', '77'],
  ['FT', '00'],
]);

export interface NoDocumentsFeedbackReq {
  searchHistory: number;
  feedback: boolean | null;
  feedbackReason: string;
}

export interface NAChatGPTInput {
  config: Config;
  problemData: ProblemData;
  applicability: NAApplicability;
}

export interface NAApplicability {
  str_year_version_code: string;
  str_model_code: string;
  str_engine_code: string;
  str_build_date: string;
  sales_codes: string[];
}
