import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import {ApiService} from "../../services/api/api.service";


@Component({
  selector: 'app-massive-upload-popup',
  templateUrl: './massive-upload-popup.component.html',
  styleUrls: ['./massive-upload-popup.component.scss'],
})
export class MassiveUploadPopupComponent implements OnInit {
  public title!: string;
  public fileId!: string;

  isHovered: boolean = false;
  isDownloadable: boolean = false;
  file?: File;
  uploading = false;

  constructor(
    public api: ApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MassiveUploadPopupComponent>
  ) {}

  ngOnInit(): void {
    switch (this.data.type) {
      case 'cinRun':
        this.title = 'Massive CIN API Run';
        break;
    }
    this.uploading = false;
  }

  filesDropped(allFile: FileList): void {
    if (!this.uploading) {
      this.file = allFile[0];
      this.upload();
    }
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
    this.upload();
    event.target.value = null;
  }

  upload() {
    if (this.file && !this.uploading) {
      this.uploading = true;
      this.isDownloadable = false;

      const formData = new FormData();
      formData.append('file', this.file);

      this.api.uploadBatchMassive(formData, this.data.type, sessionStorage.getItem('userId')).subscribe(
        (resp: any) => {
          this.dialogRef.close(true);
        },
        (error: any) => {
          this.dialogRef.close(false);
        }
      );
    }
  }
}
