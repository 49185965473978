<div class="container-fluid">
  <div class="item-proposal-container row p-5">
    <div class="row mt-3 d-flex justify-content-between">
      <div class="col-12 title">Massive Runs</div>
    </div>
    <div class="row mt-5 d-flex justify-content-end">
      <div
        class="col-12 d-flex justify-content-end align-items-end deleted-items-button-container"
      >
        <button
          mat-button
          class="deleted-items-button me-3"
          (click)="getMassiveRuns()"
        >
          REFRESH <mat-icon>refresh</mat-icon>
        </button>
        <button
          *ngIf="tabIndex == 1"
          mat-button
          class="deleted-items-button"
          (click)="newRunPopup('cinRun')"
        >
          NEW RUN <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>
    <!-- Tab -->
    <mat-tab-group #tabGroup [(selectedIndex)]="tabIndex" class="mt-4">
      <mat-tab>
        <ng-template mat-tab-label>
          <div class="row">
            <div class="col-6 d-flex align-items-start">
              <label class="heading">CIN API Massive Run</label>
            </div>
            <div class="col-6 d-flex justify-content-end num-ticket">
              <div
                class="num-ticket"
                *ngIf="cinRuns.table.length === 1; then one; else more"
              ></div>
              <ng-template #one>
                <label>{{ cinRuns.table.length }} Run</label>
              </ng-template>
              <ng-template #more
                ><label>{{ cinRuns.table.length }} Runs</label>
              </ng-template>
            </div>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <generic-table
            [defaultSorting]="defaultSorting"
            (S3Event)="downloadFile($event, 'CIN_RUN')"
            [ELEMENT_DATA]="cinRuns.table"
            [displayedColumns]="columns"
          >
          </generic-table>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
