import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {ApiService} from "../../services/api/api.service";
import {ErrorPopupComponent} from "../../popup/error-popup/error-popup.component";
import {MassiveUploadPopupComponent} from "../../popup/massive-upload-popup/massive-upload-popup.component";
import {ApiCallResultPopupComponent} from "../../popup/api-call-result-popup/api-call-result-popup.component";

@Component({
  selector: 'app-massive-page',
  templateUrl: './massive-page.component.html',
  styleUrls: ['./massive-page.component.scss'],
})
export class MassivePageComponent implements OnInit {
  public cinRuns = { table: [] };
  @Input() tabIndex: number = 1;

  public columns = {
    id: 'ID',
    runDate: 'Run Date',
    owner: 'Owner',
    updateStatus: 'Status',
    fileName: 'Download',
  };

  public defaultSorting: { colName: string; sortValue: string; index: 1 } = {
    colName: 'runDate',
    sortValue: 'desc',
    index: 1,
  };

  constructor(
    private service: ApiService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getMassiveRuns();
  }

  getMassiveRuns() {
    //this.spinner.show();
    this.service.getBatchMassiveRuns().subscribe((resp: any) => {
      this.cinRuns.table = resp.cinRuns ?? [];
      //this.spinner.hide();
    });
  }


  newRunPopup(type: string) {
    let dialog = this.dialog.open(MassiveUploadPopupComponent, {
      panelClass: 'upload',
      disableClose: true,
      data: { type: type },
    });

    dialog.afterClosed().subscribe((close: any) => {
      if (close === true) {
        this.openPopup(
          {
            message: 'Upload successful',
            faultyIds: null,
          },
          ApiCallResultPopupComponent
        );
        this.getMassiveRuns();
      }
    });
  }

  downloadFile(id: number, type: string) {
    this.service.downloadBatchFile(id).subscribe(
      (response: any) => {
        const blob = new Blob([response.body], {
          type: 'application/octet-stream',
        });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        const contentDispositionHeader = response.headers.get(
          'Content-Disposition'
        );
        const match = contentDispositionHeader ? contentDispositionHeader.match(/filename="(.+?)"/) : null;
        downloadLink.download = match ? match[1] : type + '.xlsx';

        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        //this.spinner.hide();
      },
      (error: any) => {
        let typedArray = new Uint8Array(error.error);
        let byteArray = Array.from(typedArray);
        let jsonString = String.fromCharCode.apply(null, byteArray);
        let jsonObject = JSON.parse(jsonString);
        this.openPopup(jsonObject, ErrorPopupComponent);
      }
    );
  }

  openPopup(data: any, type: any) {
    this.dialog.open(type, {
      panelClass: 'error',
      disableClose: true,
      data: data,
    });
  }
}
