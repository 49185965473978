import {
  Directive,
  HostListener,
  Output,
  EventEmitter
} from "@angular/core";
import { DomSanitizer } from '@angular/platform-browser';

// export interface FileHandle {
//   file: File,
//   url: SafeUrl
// }

@Directive({
  selector: "[appDrag]"
})
export class DragDirective {
  @Output() files: EventEmitter<FileList> = new EventEmitter();


  constructor(private sanitizer: DomSanitizer) { }

  @HostListener("dragover", ["$event"]) public onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event']) public onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();

    const files = evt.dataTransfer?.files;
    if (files && files.length > 0) {
      const allowedExtensions = ["csv", "xlsx"];
      const validFiles = Array.from(files).filter(file => {
        const fileExtension = file.name.split('.').pop()!.toLowerCase();
        return allowedExtensions.includes(fileExtension);
      });

      if (validFiles.length > 0) {
        const fileList = new ClipboardEvent("").clipboardData || new DataTransfer();
        validFiles.forEach(file => {
          fileList.items.add(file);
        });
        this.files.emit(fileList.files);
      }
    }
  }
}
