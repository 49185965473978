<div class="container">
    <div class="row mt-2">
      <div class="col-6" mat-dialog-title>
        <p><strong>{{title}}</strong></p>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <p mat-dialog-close (click)="dialogRef.close(false)">X</p>
      </div>
    </div>
    <input type="file" class="file-input" accept=".xlsx,.csv" (change)="onFileSelected($event)" #fileUpload hidden
      [disabled]="uploading">
    <div class="file-upload mt-3">
      <div class="rmpm dropzone" appDrag (files)="filesDropped($event)"
        [ngStyle]="{'cursor': (uploading? 'not-allowed':'pointer')}">
        <div class="container-fluid mt-2 mb-2">
          <div class="row">
            <div class="col-12 d-flex align-items-center">
              <p class="text-left" style="word-break: break-word; white-space: pre-line;">{{ file ? file.name : 'Click on "Upload" button and choose a CSV to run ' + title }}
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-end">
              <button mat-button color="primary" class="similarity-button d-flex" (click)="fileUpload.click()"
                [ngStyle]="{'cursor': (uploading? 'not-allowed':'pointer')}"> UPLOAD
                <mat-icon>file_upload</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <mat-progress-bar class="progress-bar mt-1" mode="indeterminate" *ngIf="uploading">
        </mat-progress-bar>
      </div>
    </div>