import { Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Document } from '../../model/ChatGPT/search/ChatGPT';
import { ApiService } from '../../services/api/api.service';
import { FeedbackReq } from '../../model/ChatGPT/FeedbackReq';
import { SubscribeItemReq } from '../../model/item-subscribe/subscribe';
import { DocumentVinComponent } from '../document-vin/document-vin.component';
import { DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-document-popup',
  templateUrl: './document-popup.component.html',
  styleUrl: './document-popup.component.scss',
})
export class DocumentPopupComponent {
  public document!: Document;
  public source!: string;
  public feedbackReason: string = '';
  public searchHistory!: number;
  public hasFeedback: boolean = true;
  public disableFeedback: boolean = false;
  private previousFeedback!: boolean | null;
  private vin!: string;
  private vinType!: string;
  private itemType!: string;
  public isLinkDisabled: boolean = false;
  loading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DocumentPopupComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      document: Document;
      searchHistory: number;
      hasFeedback: boolean;
      vin: string;
      itemType: string;
      vinType: string;
      source: string;
      isLinkDisabled: boolean;
    },
    private service: ApiService,
    private dialog: MatDialog
  ) {
    this.document = data.document;
    this.searchHistory = data.searchHistory;
    this.previousFeedback = this.document.feedback;
    this.hasFeedback = data.hasFeedback;
    this.vin = data.vin;
    this.vinType = data.vinType;
    this.itemType = data.itemType;
    this.source = data.source;
    this.isLinkDisabled = data.isLinkDisabled;
  }

  openVinPopup() {
    if (!this.hasFeedback && !this.vin) {
      let dialog = this.dialog.open(DocumentVinComponent, {
        width: '600px',
      });

      dialog.afterClosed().subscribe((event: any) => {
        if (event != undefined && event != '') {
          this.dialogRef.close(event);
        }
      });
    }
  }

  setFeedback(value: boolean) {
    this.disableFeedback = true;
    if (this.document.feedback === value) {
      this.document.feedback = null;
    } else {
      this.document.feedback = value;
    }
    if (this.document.feedback !== false) this.saveFeedback();
  }

  saveFeedback() {
    let req: FeedbackReq = new FeedbackReq(
      this.searchHistory,
      this.document.title,
      this.feedbackReason,
      this.document.feedback
    );

    this.service.saveDocumentFeedback(req).subscribe(
      (resp: any) => {
        this.disableFeedback = false;
        this.previousFeedback = this.document.feedback;
        this.dialogRef.close();
      },
      (err: any) => {
        this.disableFeedback = false;
        this.document.feedback = this.previousFeedback;
        this.dialogRef.close();
      }
    );
  }

  subscribeItem() {
    this.loading = true;
    let req: SubscribeItemReq = {
      itemNumber: this.document.title,
      sessionId: sessionStorage.getItem('sessionId')!,
      userId: sessionStorage.getItem('userId')!,
      itemType: this.itemType,
      solutionType: this.document.item_solution_type,
      vin: this.vin,
      customerSymptoms: this.document.customerSymptoms,
      vinType: this.vinType,
      url: this.document.url,
    };

    this.service.subscribeItem(req).subscribe((resp: any) => {
      this.getUpdatedSubscribedItemsNumbers();
      this.loading = false;
    });
  }

  getUpdatedSubscribedItemsNumbers() {
    this.service
      .getSubscribedItemsNumber(sessionStorage.getItem('userId')!)
      .subscribe(
        (resp: any) => {
          sessionStorage.setItem('itemsSubscribed', JSON.stringify(resp.items));
        },
        (error) => (this.loading = false)
      );
  }

  getSubscribedItemsNumber(): string[] {
    return JSON.parse(sessionStorage.getItem('itemsSubscribed')!);
  }
}
