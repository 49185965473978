import { AfterViewInit, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackReq } from '../../model/ChatGPT/FeedbackReq';
import { Document } from '../../model/ChatGPT/search/ChatGPT';
import { FeedbackPopupComponent } from '../../popup/feedback-popup/feedback-popup.component';
import { ApiService } from '../../services/api/api.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrl: './feedback.component.scss',
})
export class FeedbackComponent implements AfterViewInit {
  @Input() document!: Document;
  @Input() source!: string;
  @Input() searchHistory!: number;
  private feedbackReason!: string;
  private previousFeedback!: boolean | null;
  public feedbackCategory!: string | null;
  public disableFeedback: boolean = false;

  constructor(private dialog: MatDialog, private service: ApiService) {}

  ngAfterViewInit(): void {
    this.previousFeedback = this.document.feedback;
  }

  setFeedback(value: boolean) {
    this.disableFeedback = true;
    if (this.document.feedback === value) {
      this.document.feedback = null;
      this.feedbackReason = '';
    } else {
      this.document.feedback = value;
    }
    if (this.document.feedback !== false) {
      this.saveFeedback();
    } else if (this.document.feedback === false) {
      this.openFeedbackPopup();
    }
  }

  openFeedbackPopup() {
    let dialog = this.dialog.open(FeedbackPopupComponent, {
      width: '600px',
      data: this.source,
      disableClose: true
    });

    dialog.afterClosed().subscribe((feedback: string[]) => {
      this.feedbackReason = feedback[0] ?? '';
      this.feedbackCategory = feedback[1] ?? null;
      this.saveFeedback();
    });
  }

  saveFeedback() {
    let req: FeedbackReq = new FeedbackReq(
      this.searchHistory,
      this.document.title,
      this.feedbackReason,
      this.document.feedback,
      this.feedbackCategory
    );

    console.log(req);

    this.service.saveDocumentFeedback(req).subscribe(
      (resp: any) => {
        this.previousFeedback = this.document.feedback;
        this.disableFeedback = false;
        console.log(this.previousFeedback);
      },
      (err: any) => {
        this.document.feedback = this.previousFeedback;
        this.disableFeedback = false;
      }
    );
  }
}
