import { FormCustomize } from '../../model/Form/FormCustomize';

export let homepageForm: FormCustomize[] = [
  {
    order: 1,
    propertyName: 'problemNature',
    style: 'col-6',
    selectValues: ['Vehicular', 'WITECH', 'DIAGBOX', 'Document', 'Portal'],
  },
  { order: 2, propertyName: 'empty', style: 'col-8' },
  {
    order: 3,
    propertyName: 'vin',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-6',
    isPrefixVisible: true,
  },
  {
    order: 4,
    propertyName: 'model',
    style: 'col-sm-12	col-md-12	col-lg-12 col-xl-6',
  },
  {
    order: 6,
    propertyName: 'dtcValues',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-6 text dtc',
  },
  {
    order: 7,
    propertyName: 'toolErrorCodeMultiple',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-6 text dtc',
    selectValues: [
      'AD001',
      'AD003',
      'AD005',
      'AD006',
      'CH001',
      'CH002',
      'CH109',
      'CHFFF',
      'DV001',
      'DV002',
      'DV003',
      'ME001',
      'ME002',
      'ME003',
      'RD001',
      'RD002',
      'TC001',
      'MSS898',
      'MSS899',
      'Code erreur 3',
      'SSD800',
      'TC005',
      'MSN327',
      'MSN328',
      'MSN329',
      'MSN330',
      'MSN331',
      'MSN332',
      'MSN333',
      'MSN334',
      'MSO324',
      'MSO325',
      'MSO326',
      'MSO328',
      'MSO331',
      'MSO332',
      'MSO333',
      'MSO334',
      'MSO335',
      'CH005',
      'CH006',
      'CH103',
      'CH106',
      'CH10F',
      'CH120',
      'CH121',
      'CH122',
      'CH125',
      'CH126',
      'CH127',
      'CH128',
      'CH130',
      'CHFFF',
      '500',
      'MSO327',
      'MSO328 E',
      'MSO329 E',
      'CH119',
      'ASD800',
      '214',
      'Code 4',
      'Code 7',
      'Code 8',
      'Code 10',
      'Code 13',
      'Code 20',
      'Code 28',
      'Code 29',
      '203',
      'ASD858',
      'ASD872',
      'MSD851',
      'MSD884',
    ],
  },
  {
    order: 8,
    propertyName: 'toolErrorCodeSingle',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-6 text dtc',
  },
  {
    order: 10,
    propertyName: 'problem',
    style: 'col-sm-12 col-md-12	col-lg-12 col-xl-12',
  },
  {
    order: 11,
    propertyName: 'search',
    style:
      'col-sm-6 col-md-6	col-lg-6 col-xl-6 justify-content-end align-items-center',
  },
  {
    order: 12,
    propertyName: 'clear',
    style:
      'col-sm-6 col-md-6	col-lg-6 col-xl-6 justify-content-start align-items-center',
  },
];
