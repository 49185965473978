<form [formGroup]="formGroup" (ngSubmit)="submitForm()" class="ps-3 pe-3">
  <div class="row">
    @for (form of formCustomize; track form) {
    <ng-container class="pt-2 pb-2">
      @if (form.propertyName === 'problemNature') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "formProblemNature" | translate }}</h5>
        </mat-label>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-select
            [formControlName]="form.propertyName"
            (ngModelChange)="onSelectionChange()"
          >
            @for (p of getSelectValues(form.propertyName); track p) {
            <mat-option [value]="p">{{ p | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'vin') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "formVehicleInformation" | translate }}</h5>
        </mat-label>
        <mat-form-field style="width: 100% !important" appearance="outline">
          <div
            matPrefix
            *ngIf="isPrefixVisible(form.propertyName)"
            class="p-2 label-active"
          >
            VIN/VIS
          </div>
          <input
            [placeholder]="'insertVinVis' | translate"
            matInput
            type="text"
            [formControlName]="form.propertyName"
          />

          @if (formGroup.get(form.propertyName)?.hasError('notAvailable')) {
          <mat-error> {{ "vinNotAvailable" | translate }}</mat-error>
          } @if (!hasVinBeenValidated) {
          <button
            matSuffix
            mat-button
            type="button"
            [disabled]="
              validateSpinner ||
              formGroup.get(form.propertyName)?.value == undefined ||
              formGroup.get(form.propertyName)!.value.length == 0
            "
            [ngClass]="{ success: validateSpinner, button: !validateSpinner }"
            class="me-2"
            (click)="validateVIN()"
          >
            @if (validateSpinner) {
            <mat-spinner diameter="20" color="white"></mat-spinner>
            } @else {
            {{ "validate" | translate }}
            }
          </button>
          } @if (formGroup.get(form.propertyName)?.hasError('notAvailable') &&
          !validateSpinner) {
          <mat-icon matSuffix class="error">cancel</mat-icon>
          } @if (hasVinBeenValidated === true) {
          <mat-icon class="success" matSuffix>check_circle_outline</mat-icon>
          }
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'vinWithoutValidation') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "formVehicleInformation" | translate }}</h5>
        </mat-label>
        <mat-form-field style="width: 100% !important" appearance="outline">
          <input
            [placeholder]="'insertVinVis' | translate"
            matInput
            type="text"
            [formControlName]="form.propertyName"
          />
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'model') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5 style="color: transparent">.</h5>
        </mat-label>
        <mat-form-field
          [matTooltip]="formGroup.get(form.propertyName)?.value"
          appearance="outline"
          style="width: 100% !important"
        >
          <div matPrefix class="p-2 label-inactive">
            {{ "formModel" | translate }}
          </div>
          <input matInput type="text" [formControlName]="form.propertyName" />
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'dtcValues') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5 style="color: transparent">.</h5>
        </mat-label>
        <mat-form-field appearance="outline" style="width: 100% !important">
          <div matPrefix class="p-2 label-active">{{ "DTC" | translate }}</div>
          <mat-chip-grid #chipGrid>
            @for (dtc of formGroup.get(form.propertyName)?.value; track $index)
            {
            <mat-chip-row (removed)="remove($index)">
              {{ dtc }}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip-row>
            }
            <input
              #dtcInput
              (input)="validateInput($event)"
              [placeholder]="'insertDTC' | translate"
              [matChipInputFor]="chipGrid"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addChipSet($event)"
            />
          </mat-chip-grid>
          <button
            mat-button
            matSuffix
            [disabled]="
              formGroup.get(form.propertyName)?.errors != null ||
              dtcInput.value == null ||
              dtcInput.value == ''
            "
            (click)="add(dtcInput)"
            class="button me-2"
          >
            {{ "enter" | translate }}
          </button>
          @if (formGroup.get(form.propertyName)?.hasError('maxlength')) {
          <mat-hint class="ps-3">{{ "DTCLengthError" | translate }}</mat-hint>
          } @if (dtcInput.value != null && dtcInput.value != '' &&
          itemPattern.test(dtcInput.value)) {
          <mat-hint class="ps-3"> {{ "clickEnter" | translate }} </mat-hint>
          } @if (formGroup.get(form.propertyName)?.hasError('pattern')) {
          <mat-hint class="ps-3">{{ "DTCFormatError" | translate }}</mat-hint>
          } @if (formGroup.get(form.propertyName)!.value.length == 10) {
          <mat-hint class="ps-3">{{
            "DTCMaxNumberError" | translate
          }}</mat-hint>
          }
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'problem') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "formProblemDescription" | translate }}</h5>
        </mat-label>
        <mat-form-field appearance="outline" style="width: 100%">
          <textarea
            matInput
            [placeholder]="
              ('describeProblem' | translate) +
              (source == undefined
                ? ''
                : source == 'Hub'
                ? ('describeProblemServiceBox' | translate)
                : ('describeProblemLinkEntry' | translate))
            "
            [formControlName]="form.propertyName"
          ></textarea>
          @if (formGroup.get(form.propertyName)?.hasError('maxlength')) {
          <mat-error>{{ "formProblemLengthError" | translate }}</mat-error>
          }
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'documentType') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "formDocumentType" | translate }}</h5>
        </mat-label>
        <mat-form-field appearance="outline" style="width: 100% !important">
          <mat-select [formControlName]="form.propertyName">
            @for (p of getSelectValues(form.propertyName); track p) {
            <mat-option [value]="p">{{ p | translate }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'documentReference') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "formDocumentReference" | translate }}</h5>
        </mat-label>
        <mat-form-field
          style="width: 100%"
          [matTooltip]="formGroup.get(form.propertyName)?.value"
          appearance="outline"
        >
          <input matInput type="text" [formControlName]="form.propertyName" />
          @if (formGroup.get(form.propertyName)?.errors &&
          !formGroup.get(form.propertyName)?.hasError('required')) {
          <mat-hint class="ps-3">{{ "documentNotValid" | translate }}</mat-hint>
          } @if (formGroup.get(form.propertyName)?.hasError('required')) {
          <mat-hint class="ps-3">{{
            "documentReferenceWarning" | translate
          }}</mat-hint>
          }
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'itemNumber') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5>{{ "itemNumber" | translate }}</h5>
        </mat-label>
        <mat-form-field
          style="width: 100% !important"
          [matTooltip]="formGroup.get(form.propertyName)?.value"
          appearance="outline"
        >
          <input
            matInput
            type="text"
            [formControlName]="form.propertyName"
            [placeholder]="'searchItem' | translate"
          />
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'empty') {
      <div [class]="getStyle(form.order)"></div>
      } @if (form.propertyName === 'search') {
      <div class="d-flex" [class]="getStyle(form.order)">
        <mat-label>
          <h5></h5>
        </mat-label>
        <button
          [disabled]="
            !formGroup.valid ||
            (formGroup.get('problemNature')?.value !== 'Vehicular' &&
              formGroup.get('vin')?.value != '' &&
              !hasVinBeenValidated)
          "
          mat-raised-button
          type="submit"
          class="col-auto d-flex justify-content-center align-items-center button"
        >
          @if (searchSpinner) {
          <mat-spinner diameter="20" color="white"></mat-spinner>
          } @else {
          {{
            isFilter(form.propertyName)
              ? ("filter" | translate)
              : ("search" | translate)
          }}
          <mat-icon style="vertical-align: middle">search</mat-icon>
          }
        </button>
      </div>
      } @if (form.propertyName === 'clear') {
      <div class="d-flex" [class]="getStyle(form.order)">
        <mat-label>
          <h5></h5>
        </mat-label>
        <button
          mat-raised-button
          type="button"
          (click)="clearFormAndResults()"
          [disabled]="checkIfClearDisabled()"
          class="col-auto d-flex justify-content-center align-items-center button"
        >
        {{ 'clearAll' | translate }}
          <mat-icon style="vertical-align: middle">autorenew</mat-icon>
        </button>
      </div>
      } @if (form.propertyName === 'toolErrorCodeMultiple' &&
      formGroup.get('problemNature')!.value == 'DIAGBOX') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5 style="color: transparent">.</h5>
        </mat-label>
        <mat-form-field
          appearance="outline"
          style="width: 100%; display: flex; align-items: center"
        >
          <div matPrefix class="p-2 label-active">
            {{ "toolErrorCode" | translate }}
          </div>
          <input
            type="text"
            [placeholder]="'diagboxPlaceholder' | translate"
            aria-label="Tool Error Code"
            matInput
            [formControlName]="form.propertyName"
            [matAutocomplete]="auto"
            class="hide-values"
          />
          <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
          <mat-autocomplete #auto="matAutocomplete">
            @for (p of filteredToolErrorCodes$ | async; track p) {
            <mat-option [value]="p">{{ p }}</mat-option>
            }
            <mat-option *ngIf="isNewValue()"
              ><button
                matSuffix
                mat-raised-button
                (click)="addNewToolErrorCode()"
                matTooltip="Add new code"
              >
                {{ 'addNew' | translate }}
              </button></mat-option
            >
          </mat-autocomplete>
        </mat-form-field>
      </div>
      } @if (form.propertyName === 'toolErrorCodeSingle' &&
      formGroup.get('problemNature')!.value == 'WITECH') {
      <div [class]="getStyle(form.order)">
        <mat-label>
          <h5 style="color: transparent">.</h5>
        </mat-label>
        <mat-form-field appearance="outline" style="width: 100%">
          <div matPrefix class="p-2 label-active">
            {{ "toolErrorCode" | translate }}
          </div>
          <input
            type="text"
            [placeholder]="'witechPlaceholder' | translate"
            aria-label="Language"
            matInput
            [formControlName]="form.propertyName"
            class="hide-values"
          />
          @if
          (formGroup.get(form.propertyName)?.hasError('invalidToolErrorCode')) {
          <mat-error> {{ "toolErrorCodeValidator" | translate }}</mat-error>
          }
        </mat-form-field>
      </div>
      }
    </ng-container>
    }
  </div>
</form>
