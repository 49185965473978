<div class="table-container">
  <mat-table style="width: max-content; overflow-x: scroll !important; min-width: 100% !important;" #table
             [dataSource]="dataSource">
    <div *ngFor="let colName of columnsKeys;let i=index">
      <ng-container matColumnDef="{{colName}}" [formGroup]="tableForm">

        <!-- Table Header -->
        <mat-header-cell *matHeaderCellDef formArrayName="table_dropDown">
          <mat-form-field
            *ngIf="colName != 'view' && colName != 'setReview' && colName != 'setNotReview' && colName != 'fileName'
              && colName != 'viewDeleted' && colName != 'viewItem' && colName != 'viewCrisis' && colName != 'viewCrisisIdentified'"
            appearance="outline" [formGroupName]="i" style="width:100%">
            <div class="d-flex align-items-center justify-content-between">
              <label style="padding-left: 2.5rem;" class="float-label">
                {{displayedColumns[colName]}} </label>
<!--              <mat-icon class="col-2 down-icon"-->
<!--                        [ngClass]="colName == 'time' ? 'highlight' : ''">arrow_drop_down</mat-icon>-->
<!--              <mat-icon *ngIf="this.table_dropDown().at(i).value[colName]?.length > 0" class="col-2 down-icon"-->
<!--                        [ngClass]="colName == 'time' ? 'highlight' : ''">filter_alt</mat-icon>-->
            </div>
            <mat-select (closed)="closeSelect(colName,i)" [formControlName]="colName" #selectControl [id]="colName"
                        multiple panelClass="filter">

              <div class="d-flex">
                <span class="dropdown-title col-10">{{displayedColumns[colName]}} </span>
                <mat-icon class="col-2 up-icon">arrow_drop_up</mat-icon>
              </div>
              <hr>
              <span class="ps-2">
                <input class="search-field" matInput [formControlName]="'search'" type="text" value="">
                <mat-icon matPrefix>search</mat-icon>
              </span>

              <mat-select-trigger></mat-select-trigger>

              <!-- Utilizzo del Virtual Scrolling -->
              <cdk-virtual-scroll-viewport itemSize="48" class="options-container">
                <mat-option [value]="0" #allSelected (click)="selectAll(colName,i)">All</mat-option>

                <ng-container *cdkVirtualFor="let select of distinctValuesMap.get(colName)">
                  <mat-option *ngIf="select != null && shouldDisplayOption(select, i)" [value]="select"
                              (click)="deselectAll(colName,i)">
                    {{ (colName === 'updateStatus' && select === true) ? 'Completed' :
                    (colName === 'updateStatus' && select === false) ? 'In progress' : select }}
                  </mat-option>
                </ng-container>
              </cdk-virtual-scroll-viewport>

              <div class="toggle-container mt-2">
                <mat-slide-toggle class="ps-4 mt-2" name="sorttable" [formControlName]="'sort'"
                                  (change)="toggleChanges($event,i)">Sort table</mat-slide-toggle>
                <div *ngIf="this.table_dropDown().at(i).get('sort')?.value">
                  <hr>
                  <mat-radio-group class="row ps-4 radio-container" [formControlName]="'sortValue'">
                    <mat-radio-button [value]="'asc'" class="mt-2 d-flex">
                      <img class="logo pe-2" ngSrc="/assets/icons/sort-alpha-down-arrow.svg"
                           alt="logoImage" style="width: auto; height: auto;" fill>
                      Ascending</mat-radio-button>
                    <mat-radio-button [value]="'desc'" class="mt-2 d-flex">
                      <img class="logo pe-2" ngSrc="/assets/icons/sort-alpha-up-arrow.svg"
                           alt="logoImage" style="width: auto; height: auto;" fill>
                      Descending</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>

              <div class="col-12 mt-3 px-2">
                <button mat-button (click)="confirm(i,colName)" class="confirm">CONFIRM</button>
              </div>
            </mat-select>

          </mat-form-field>
        </mat-header-cell>

        <!-- Table Content -->
        <!-- Table Content -->
        <div *ngIf="colName == 'updateStatus'">
          <mat-cell *matCellDef="let element">
            <span>{{element.updateStatus===true?'Completed':'In progress'}}</span>
          </mat-cell>
        </div>
        <div *ngIf="colName == 'fileName'" class="view">
          <mat-cell *matCellDef="let element" [ngClass]="'highlight'">
            <button mat-button [disabled]="element.updateStatus !== true" class="similarity-button"
                    (click)="generateS3Event(element.id)">DOWNLOAD
              <mat-icon [ngClass]="element.updateStatus !== true?'disabled':''" class="download-icon">get_app</mat-icon>
            </button>
          </mat-cell>
        </div>
        <mat-cell class="m-1" style="max-height: 5rem; align-items:start;" *matCellDef="let element"
                  [matTooltip]="shouldShowTooltip(colName) ? element[colName] : null" matTooltipClass="tooltip">
          <span [innerHTML]="returnValue(colName, element)"></span>
        </mat-cell>

      </ng-container>
    </div>

    <mat-header-row *matHeaderRowDef="columnsKeys"></mat-header-row>
    <mat-row style="max-height: 5rem; text-align: center;" *matRowDef="let row; columns: columnsKeys;"
             [ngClass]="{'new-ticket': row.thereAreNewTickets}"></mat-row>
  </mat-table>

  <!-- Paginator -->
  <mat-paginator *ngIf="isDataDisplayed" [pageSizeOptions]="[5,10,15,30,50,100]" [pageSize]="10" showFirstLastButtons
                 class="float-center">
  </mat-paginator>

  <!-- No Data -->
  <div class="d-flex justify-content-center warning" *ngIf="!isDataDisplayed">
    <label class="m-3">No matches found</label>
  </div>
</div>
