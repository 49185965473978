import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, shareReplay } from 'rxjs/operators';
import {ApiService} from "./api/api.service";

@Injectable({
  providedIn: 'root'
})
export class AllowedUserService {
  private allowedUserIds$: Observable<string[]> | null = null;

  constructor(private apiService: ApiService) {}

  getAllowedUserIds(): Observable<string[]> {
    if (!this.allowedUserIds$) {
      this.allowedUserIds$ = this.apiService.getAllowedUserids().pipe(
        tap((resp: any) => console.log('Dati ricevuti:', resp)), // Debug
        tap(resp => resp.allowedUserIds ?? []),
        shareReplay(1)
      );
    }
    return this.allowedUserIds$;
  }
}
